import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal, useProfile} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Вопрос о продукте',
        id: 6
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Feedback({product = false}){
    const {profile} =  useProfile();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        text: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
        theme_id: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true], 'Необходимо согласие')
    }).required();

    const { register,reset, setValue, clearErrors, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        setValue('agree', true);
        if(product){
            setValue('theme', 6);
        }
    }, []);

    const watchEmail = watch("email");
    const watchName = watch("name");
    const watchMess = watch("text");
    const watchTheme = watch("theme_id");

    useEffect(() => {
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            setValue('name', profile.data.name);
            setValue('email', profile.data.email);
        }
    },[profile]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.text);
        formData.append("theme_id", data.theme_id);
        formData.append("agree", 'yes');

        if(fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        try {
            const response = await axiosInstance.post('/feedbacks/api/send', formData);
            if(response.data){
                let _email = data.email;
                let _text = 'Ваш вопрос будет обработан в&nbsp;течение 72&nbsp;часов. Ответ будет отправлен на&nbsp;указанный электронный адрес <a>' + _email + '</a>';
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    icon={true}
                    title="Cпасибо за&nbsp;обращение"
                    text={_text}
                    close={hide}/>, "modal--info");

                reset();
                if(localStorage.getItem('auth_key')){
                    setValue('name', profile.data.name);
                    setValue('email', profile.data.email);
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Задать вопрос
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder="Имя"
                                            {...register("name")}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                        <input
                                            autoComplete="username"
                                            type="text"
                                            id="email"
                                            placeholder="E-mail"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (errors.theme_id ? ' has-error' : '')}>
                                        <div onClick={()=>{
                                            setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                        }} className="form__select-input">
                                            <div className={'form__select-value' + (theme == '' ? ' form__select-value--placeholder' : '')}>
                                                {theme != '' ? themeTitle : 'Тема обращения'}
                                            </div>
                                            <div className="form__select-toggle">
                                                <svg className="desk-only" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1.5L6 6.5L11 1.5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                                <svg className="mb-only" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1.37579L7 7.37579L13 1.37579" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__select-dropdown-list">
                                                {topics.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(false);
                                                        setThemeTitle(it.title);
                                                        setTheme(it.id);
                                                        setValue('theme_id', it.id);
                                                        clearErrors('theme_id');
                                                    }} className={'form__select-dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {errors.theme_id && (
                                        <p className="error__message">
                                            {errors.theme_id?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.text ? ' has-error' : '')}>
                                        <textarea
                                            id="text"
                                            placeholder="Текст обращения"
                                            {...register("text")}
                                        />
                                    </div>
                                    {errors.text && (
                                        <p className="error__message">
                                            {errors.text?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--attach">
                                    <label className="form__attach-btn">
                                        <input type="file" id="file" {...register("attachment")} accept="image/*" onChange={(event)=>{
                                            const fileInput = document.querySelector('#file') ;
                                            const files = event.target.files;
                                            const fileName = files[0].name;
                                            setAttachFile(fileName);
                                        }}/>
                                        <div className={'file__attach-btn-icon' + (attachFile != '' ? ' has-image' : '')}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 0L9 18" stroke="black" strokeWidth="2"/>
                                                <path d="M18 9L0 9" stroke="black" strokeWidth="2"/>
                                            </svg>
                                        </div>
                                        <span>{attachFile ? attachFile : 'Прикрепить изображение'}</span>

                                        {attachFile != '' && (
                                            <div onClick={(e)=>{
                                                setValue('attachment', null);
                                                setAttachFile('');
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }} className="file__clear">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2.69235L2.69231 2.00005L19.9999 19.3077L19.3076 20L2 2.69235Z" fill="#F00000" stroke="#F00000"></path><path d="M19.3077 2L20 2.69231L2.69237 20L2.00006 19.3076L19.3077 2Z" fill="#F00000" stroke="#F00000"></path></svg>
                                            </div>
                                        )}
                                    </label>

                                </div>
                                <div className="col-12 form__it">
                                    <div className="checkbox__it checkbox__it--top">
                                        <div className={'checkbox__wrap' + (errors.agree ? ' has-error' : '')}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    {...register("agree")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я&nbsp;даю согласие на&nbsp;обработку <a href="/user-agreement.pdf" target="_blank">персональных данных</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree && (
                                            <p className="error__message">
                                                {errors.agree?.message}
                                            </p>
                                        )}
                                    </div>

                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <button type="submit" className="btn btn--full">
                                        <span>Задать вопрос</span>
                                        <i className="btn__arrow">
                                            <svg>
                                                <use href="#btn-arrow"></use>
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Feedback;
