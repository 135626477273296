import {useModal, useProfile} from "../../context";
import Login from "./../modals/Login";
import React from "react";

function CodesWidget({classesList = null, title = 'На сайте', icon = false, closeNav = null, module = null}) {
    const {profile} =  useProfile();
    const { show, hide} = useModal();

    return (
        <>
            <a onClick={()=>{
                if(closeNav){
                    closeNav();
                }
                if(profile.loggedIn && profile.data.promocode) {
                    if(window.promoTools) {
                        window.promoTools.checkGrabberWidget.settings = {
                            skin: 'custom',
                            api: process.env.REACT_APP_API_PROXY,
                            method: '/receipts/api/widgets/receipts',
                            userUuid: profile.data.promocode,
                            maxFiles: 5,
                            fields: {
                                manual: {
                                    photos: {
                                        required: false
                                    },
                                },
                            },
                            i18n: {
                                qr: {
                                    title: 'Фото чека',
                                    subTitle: 'Для сканирования чека наведите <br/>камеру на QR-код на чеке',
                                    btnQr: 'Сканировать QR-код чека',
                                    btnManual: 'Ввести вручную',
                                    btnPhotos: 'Загрузить фото чека',
                                    btnBackScanner: 'Назад к сканеру чеков',
                                    cameraError: {
                                        title: '<i class="icon__error"></i><div class="check-grabber__title">Нет доступа к камере</div><div>При запросе браузером доступа <br/>к камере нажмите «разрешить»</div>',
                                    },
                                },
                                manual: {
                                    title: 'Ввести данные <br/> с чека вручную',
                                    subTitle: '',
                                },
                                photos: {
                                    title: 'Загрузка чека',
                                    subTitle: 'Добавьте фото чека',
                                    btnPhotos: 'Отправить',
                                },
                                pending: {
                                    title: 'Подождите!',
                                    subTitle: 'Идет отправка чека',
                                },
                                fail: {
                                    title: 'Не удалось <br/>отправить чек!',
                                },
                                success: {
                                    title: '<i class="icon__success"></i><div>Спасибо! <br/>Ваш чек загружен</div>',
                                    subTitle: 'Ваш чек успешно зарегистрирован <br/>Информация о&nbsp;результатах розыгрыша доступна в&nbsp;разделе <a onclick="if(document.querySelector(\'#close-check-modal\')){document.querySelector(\'#close-check-modal\').click();}" href="/cabinet#codes">чеки и&nbsp;призы</a>',
                                },
                            },
                            events: {
                                onSentSuccess(res) {
                                    let elUp1 = document.getElementById('update-codes');
                                    if(elUp1){
                                        elUp1.click();
                                    }

                                    let elUp2 = document.getElementById('inc-codes');
                                    if(elUp2){
                                        elUp2.click();
                                    }

                                    if(window.ym){
                                        window.ym(97392085,'reachGoal','успешная регистрация чека');
                                    }
                                },
                            },
                        };
                        window.promoTools.checkGrabberWidget.show();
                    }
                }else {
                    show(<Login/>, "modal--form modal--login");
                }

                if(module === 'main'){
                    if(window.ym){
                        window.ym(97392085,'reachGoal','кнопка Зарегистрировать чек на главной');
                    }
                }

                if(module === 'lk') {
                    if(window.ym){
                        window.ym(97392085,'reachGoal','кнопка Зарегистрировать чек в кабинете');
                    }
                }
            }} className={classesList} id="rec-trigger-show">
                <span>{title}</span>
                {icon && (
                    <i className="btn__arrow">
                        <svg>
                            <use href="#btn-arrow"></use>
                        </svg>
                    </i>
                )}
            </a>
        </>
    );
}

export default CodesWidget;
