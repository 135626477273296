import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useModal, useProfile} from "../context";
import Feedback from "./modals/Feedback";
function Footer({screen, moveDown}) {
    const location = useLocation();
    const {profile} =  useProfile();
    const [inner, setInner] = useState(false);
    const { show, hide} = useModal();

    useEffect(() => {
        setInner(location.pathname != '/');
    }, [location.pathname]);

    return (
        <>
            <div className={'footer' + (location.pathname === '/end' ? ' footer--thin' : '')}>
                <div className="footer__bg-top">
                    <svg className="desk-only" viewBox="0 0 1600 141" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                        <path d="M0 50C0 50 336.022 0 800 0C1263.98 0 1600 50 1600 50V141H0V50Z" fill="#FF0000"/>
                    </svg>
                    <svg className="mb-only" viewBox="0 0 360 32" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                        <path d="M0 11.25C0 11.25 75.605 0 180 0C284.395 0 360 11.25 360 11.25V31.725H0V11.25Z" fill="#FF0000"/>
                    </svg>

                </div>
                <div className="container">
                    <div className="footer__box">
                        <div className="footer__nav">
                            <ul>
                                <li>
                                    <a href="/policy.pdf" target="_blank">Политика конфиденциальности</a>
                                </li>
                                <li>
                                    <a onClick={()=>{
                                        if(window.ym){
                                            window.ym(97392085,'reachGoal','кнопка Правила');
                                        }
                                    }} href="/rules.pdf" target="_blank">Полные правила акции</a>
                                </li>
                                <li>
                                    <a onClick={()=>{
                                        show(<Feedback product={true}/>, "modal--form modal--feedback");
                                        if(window.ym){
                                            window.ym(97392085,'reachGoal','кнопка Задать вопрос');
                                        }
                                    }}>Обратная связь</a>
                                </li>
                            </ul>
                        </div>
                        <div className="footer__legal">
                            Акция проводится в&nbsp;магазинах сети &laquo;Магнит&raquo; в&nbsp;форматах: &laquo;Магнит у&nbsp;дома&raquo;, &laquo;Магнит Семейный&raquo;, &laquo;Магнит Экстра&raquo;. Общий срок с&nbsp;1&nbsp;октября по&nbsp;27&nbsp;декабря. Регистрация с&nbsp;1&nbsp;октября по&nbsp;24&nbsp;ноября. Полную информацию о&nbsp;правилах проведения Акции, порядке определения победителей Акции, количестве призов по&nbsp;результатам Акции, сроках, месте и&nbsp;порядке их&nbsp;получения можно получить на&nbsp;сайте Акции <a href="https://delicados-promo.ru">delicados-promo.ru</a>. Количество призов ограничено. Внешний вид товаров и&nbsp;призов может отличаться от&nbsp;изображённого в&nbsp;рекламных материалах. Имеются ограничения.
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Footer;
