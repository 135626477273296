import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from "react-input-mask";
import * as yup from "yup";
import {useModal} from "../../context";
import Login from "./Login";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

function Reg({email, finish = false, token = null}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [codeEnabled, setCodeEnabled] = useState(false);
    const { show, hide} = useModal();

    const schemaObj = {
        first_name: yup.string().required('Некорректное имя'),
        last_name: yup.string().required('Некорректная фамилия'),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                })
                .required('Некорректный телефон'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        agree_rules: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree_other: yup.boolean().oneOf([true], 'Подтвердите ваш возраст'),
        agree_subscribe: yup.boolean().oneOf([false, true], 'Необходимо согласие'),
    };

    const schema = yup.object(schemaObj).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchAgree1 = watch("agree_rules");
    const watchAgree2 = watch("agree_other");
    const watchAgree3 = watch("agree_subscribe");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("phone", data.phone);
        formData.append("agree_rules", 'yes');
        formData.append("agree_other", 'yes');

        if(watchAgree3){
            formData.append("agree_subscribe", 'yes');
        }

        let axiosConfig = {};

        if(finish && token){
            axiosConfig = {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            };
        }

        try {
            const response = await axiosInstance.post((finish ? '/profiles/api/finish-profile' : '/profiles/api/register-by-email'), formData, axiosConfig);
            if(response.data){
                show(
                    <Info
                        title="Спасибо за&nbsp;регистрацию!"
                        text="Пароль доступа в&nbsp;личный кабинет отправлен на&nbsp;указанную почту"
                        icon={true}
                        close={hide}/>,
                    "modal--info"
                );

                if(window.ym){
                    window.ym(97392085,'reachGoal','успешная регистрация пользователя');
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if(email && email != 0){
            setValue('email', email);
        }

    },[]);

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            {finish ? 'Завершение регистрации' : 'Регистрация'}
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.first_name ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="first_name"
                                            autoComplete="nope"
                                            placeholder="Имя"
                                            {...register("first_name")}
                                        />
                                    </div>
                                    {errors.first_name && (
                                        <p className="error__message">
                                            {errors.first_name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.last_name ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="last_name"
                                            autoComplete="nope"
                                            placeholder="Фамилия"
                                            {...register("last_name")}
                                        />
                                    </div>
                                    {errors.last_name && (
                                        <p className="error__message">
                                            {errors.last_name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                        <InputMask
                                            autoComplete="nope"
                                            type="text"
                                            id="phone"
                                            mask="+7 999-999-99-99"
                                            placeholder="Телефон"
                                            alwaysShowMask={false}
                                            {...register("phone")}
                                        />
                                    </div>
                                    {errors.phone && (
                                        <p className="error__message">
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                        <input
                                            type="text"
                                            id="email"
                                            placeholder="E-mail"
                                            autoComplete="nope"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <div className="checkbox__it checkbox__it--top">
                                        <div className={'checkbox__wrap' + (errors.agree_other ? ' has-error' : '')}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    {...register("agree_other")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title">
                                                    Я даю согласие на обработку <a href="/user-agreement.pdf" target="_blank">персональных данных</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree_other && (
                                            <p className="error__message">
                                                {errors.agree_other?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree_rules ? ' has-error' : '')}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    {...register("agree_rules")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я согласен с <a href="/rules.pdf" target="_blank">правилами акции</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree_rules && (
                                            <p className="error__message">
                                                {errors.agree_rules?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree_subscribe ? ' has-error' : '')}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    {...register("agree_subscribe")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я&nbsp;даю согласие на&nbsp;получение <a href="/user-agreement.pdf" target="_blank">рассылки
                                                    рекламного характера</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree_subscribe && (
                                            <p className="error__message">
                                                {errors.agree_subscribe?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <button disabled={isSubmitting || !watchAgree1 || !watchAgree2} type="submit" className="btn btn--full">
                                        <span>{finish ? 'Отправить' : 'Зарегистрироваться'}</span>
                                        <i className="btn__arrow">
                                            <svg>
                                                <use href="#btn-arrow"></use>
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {!finish && (
                    <div className="modal__ct-box">
                        <div className="modal__subtitle">
                            Уже есть аккаунт? <a onClick={()=>{
                            show(<Login/>, "modal--form modal--login");
                        }} >Войти</a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Reg;
