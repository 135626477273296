import parse from 'html-react-parser';
import React from "react";

function Lock({close}){
    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <i className="icon__end" aria-hidden={true}/>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__title">
                        Регистрация чеков
                        завершена
                    </div>
                </div>
                <div className="modal__ct-box">
                    <div className="modal__text">
                        Спасибо за участие!
                    </div>
                </div>
                <div className="modal__ct-box modal__action-close">
                    <div onClick={()=>{
                        close()
                    }} className="btn">
                        <span>Закрыть</span>
                        <i className="btn__arrow">
                            <svg>
                                <use href="#btn-arrow"></use>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lock;
