import { Link, useLocation  } from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useProfile, useModal, useEnd} from "../context";
import classNames from "classnames";
import Login from "./modals/Login";
import Info from "./modals/Info";
import ChangePass from "./modals/ChangePass";
import Prize from "./modals/Prize";
import Lock from "./modals/Lock";
import RegTg from "./modals/RegTg";
import Game from "./modals/Game";
function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [lkNav, setLkNav] = useState(false);
    const location = useLocation();
    const {end} = useEnd();
    const {profile} =  useProfile();

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }, [location.pathname]);

    useEffect(()=>{
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            let exist = false;
            Object.keys(profile.data.prizes).map((it, i) => {
                if(profile.data.prizes[it].push === 'no' && !exist){
                    show(<Prize prize={profile.data.prizes[it]}/>, "modal--prize");
                    exist = true;
                }
            });

        }
    }, [profile]);

    useEffect(()=>{
        if(!sessionStorage.getItem('ac_end')){
            sessionStorage.setItem('ac_end', 'true');
            show(<Lock close={hide}/>, "modal--lock");
        }

    }, []);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > 5){
                setFixed(true);
            }else{
                setFixed(false);
            }

            if(document.getElementById('actions-codes')){
                if(window.scrollY > window.outerHeight * 0.75){
                    document.getElementById('actions-codes').classList.add('active');
                }else{
                    document.getElementById('actions-codes').classList.remove('active');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }

    return (
        <>
            <div id="header"
                 className={
                     classNames('header fadeIn animated', (inner ? ' header--inner' : ''),
                         (nav ? ' active header--open' : ''),
                         (fixed ? ' header--fixed' : ''),
                         (!profile.loading ? ' header--loaded' : ''),
                         (profile.loggedIn ? ' header--logged-in' : '')
                     )}>
                <div className="container">
                    <div className="nav__box fadeIn animated">
                        <div className="nav__bl">
                            <div className="nav__menu">
                                <ul>
                                    <li>
                                        <Link to="/#steps" onClick={(e)=>{
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('steps');
                                            }
                                            if(window.ym){
                                                window.ym(97392085,'reachGoal','кнопка Как участвовать');
                                            }
                                        }}>КАК УЧАСТОВАТЬ?</Link>
                                    </li>
                                    <li>
                                        <Link to="/#prizes" onClick={(e)=>{
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('prizes');
                                            }
                                            if(window.ym){
                                                window.ym(97392085,'reachGoal','кнопка Призы');
                                            }
                                        }}>Призы</Link>
                                    </li>
                                    <li>
                                        <li>
                                            <Link to="/#game" onClick={(e)=>{
                                                if(!inner) {
                                                    e.preventDefault();
                                                    goToAnchor('game');
                                                }

                                                if(inner){
                                                    if(window.ym){
                                                        window.ym(97392085,'reachGoal','кнопка Играть сейчас в кабинете');
                                                    }
                                                }else{
                                                    if(window.ym){
                                                        window.ym(97392085,'reachGoal','кнопка Играть сейчас');
                                                    }
                                                }
                                            }}>Играть сейчас!</Link>
                                        </li>
                                    </li>
                                    <li>
                                        <li>
                                            <Link to="/#buy" onClick={(e)=>{
                                                if(!inner) {
                                                    e.preventDefault();
                                                    goToAnchor('buy');
                                                }
                                                if(window.ym){
                                                    window.ym(97392085,'reachGoal','кнопка Где купить');
                                                }
                                            }}>Где купить</Link>
                                        </li>
                                    </li>
                                    <li>
                                        <Link to="/#products" onClick={(e)=>{
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('products');
                                            }
                                        }}>Продукт</Link>
                                    </li>
                                    <li>
                                        <Link to="/#winners" onClick={(e)=>{
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('winners');
                                            }
                                        }}>Победители</Link>
                                    </li>
                                    <li>
                                        <Link to="/#faq" onClick={(e)=>{
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('faq');
                                            }
                                        }}>Вопросы</Link>
                                    </li>
                                    <li>
                                        <a href="/rules.pdf" target="_blank" onClick={()=>{
                                            if(window.ym){
                                                window.ym(97392085,'reachGoal','кнопка Правила');
                                            }
                                        }} >Правила</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-only">
                                {Object.keys(profile.data).length === 0 && (
                                    <div onClick={()=>{
                                        show(<Login/>, "modal--form modal--login");
                                    }} className="btn-hd">
                                        <i className="icon__user">
                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49439 17.4588C0.413094 16.6827 0.169447 15.2615 0.982372 14.2408C2.93098 11.7943 5.96143 10.2222 9.36497 10.2222C12.8042 10.2222 15.8625 11.8274 17.8085 14.3181C18.6115 15.3458 18.354 16.7653 17.2646 17.5319C15.0566 19.0857 12.3523 20 9.43094 20C6.46408 20 3.72103 19.0569 1.49439 17.4588Z" fill="black"/>
                                                <path d="M14.1671 4.44444C14.1671 6.89904 12.0269 8.88889 9.38678 8.88889C6.74668 8.88889 4.60645 6.89904 4.60645 4.44444C4.60645 1.98985 6.74668 0 9.38678 0C12.0269 0 14.1671 1.98985 14.1671 4.44444Z" fill="black"/>
                                            </svg>
                                        </i>
                                        <span>Личный кабинет</span>
                                    </div>
                                )}
                                {Object.keys(profile.data).length > 0 && (
                                    <>
                                        <div className={'btn__lk' + (lkNav ? ' active' : '')}>
                                            <Link to="/cabinet" className="btn-hd">
                                                <i className="icon__user">
                                                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.49439 17.4588C0.413094 16.6827 0.169447 15.2615 0.982372 14.2408C2.93098 11.7943 5.96143 10.2222 9.36497 10.2222C12.8042 10.2222 15.8625 11.8274 17.8085 14.3181C18.6115 15.3458 18.354 16.7653 17.2646 17.5319C15.0566 19.0857 12.3523 20 9.43094 20C6.46408 20 3.72103 19.0569 1.49439 17.4588Z" fill="black"/>
                                                        <path d="M14.1671 4.44444C14.1671 6.89904 12.0269 8.88889 9.38678 8.88889C6.74668 8.88889 4.60645 6.89904 4.60645 4.44444C4.60645 1.98985 6.74668 0 9.38678 0C12.0269 0 14.1671 1.98985 14.1671 4.44444Z" fill="black"/>
                                                    </svg>
                                                </i>
                                                <span>Личный кабинет</span>
                                            </Link>
                                        </div>
                                        <div className="nav__menu-lk">
                                            <div onClick={()=>{
                                                show(<ChangePass/>, "modal--form modal--pass");
                                            }} className="btn__lk-dropdown-it">
                                                <i className="icon__logout"/>
                                                <span>
                                                   Сменить пароль
                                               </span>
                                            </div>
                                            <a onClick={()=>{
                                                if(window.ym){
                                                    window.ym(97392085,'reachGoal','кнопка Перехода в мессенджер');
                                                }
                                            }} href={'https://t.me/delicados_promo_bot?start=' + profile.data.promocode} target="_blank" className="btn__lk-dropdown-it">
                                                <i className="icon__tg"/>
                                                <span>
                                                   Связать кабинет <br/>
                                                    с ассистентом акции
                                               </span>
                                            </a>

                                            <div onClick={()=>{
                                                localStorage.removeItem('auth_key');
                                                window.location.reload();
                                            }} className="btn__lk-dropdown-it">
                                                <i className="icon__logout"/>
                                                <span>
                                                   Выход
                                               </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="header__right desk-only">
                        {!profile.loading && (
                            <div className="header__right-it">
                                {Object.keys(profile.data).length === 0 && (
                                    <div onClick={()=>{
                                        show(<Login/>, "modal--form modal--login");
                                    }} className="btn-hd">
                                        <i className="icon__user">
                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49439 17.4588C0.413094 16.6827 0.169447 15.2615 0.982372 14.2408C2.93098 11.7943 5.96143 10.2222 9.36497 10.2222C12.8042 10.2222 15.8625 11.8274 17.8085 14.3181C18.6115 15.3458 18.354 16.7653 17.2646 17.5319C15.0566 19.0857 12.3523 20 9.43094 20C6.46408 20 3.72103 19.0569 1.49439 17.4588Z" fill="black"/>
                                                <path d="M14.1671 4.44444C14.1671 6.89904 12.0269 8.88889 9.38678 8.88889C6.74668 8.88889 4.60645 6.89904 4.60645 4.44444C4.60645 1.98985 6.74668 0 9.38678 0C12.0269 0 14.1671 1.98985 14.1671 4.44444Z" fill="black"/>
                                            </svg>
                                        </i>
                                        <span>Личный кабинет</span>
                                    </div>
                                )}

                                {Object.keys(profile.data).length > 0 && (
                                    <div className={'btn__lk' + (lkNav ? ' active' : '')}>
                                        <Link to="/cabinet" className="btn-hd">
                                            <i className="icon__user">
                                                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.49439 17.4588C0.413094 16.6827 0.169447 15.2615 0.982372 14.2408C2.93098 11.7943 5.96143 10.2222 9.36497 10.2222C12.8042 10.2222 15.8625 11.8274 17.8085 14.3181C18.6115 15.3458 18.354 16.7653 17.2646 17.5319C15.0566 19.0857 12.3523 20 9.43094 20C6.46408 20 3.72103 19.0569 1.49439 17.4588Z" fill="black"/>
                                                    <path d="M14.1671 4.44444C14.1671 6.89904 12.0269 8.88889 9.38678 8.88889C6.74668 8.88889 4.60645 6.89904 4.60645 4.44444C4.60645 1.98985 6.74668 0 9.38678 0C12.0269 0 14.1671 1.98985 14.1671 4.44444Z" fill="black"/>
                                                </svg>
                                            </i>
                                            <span>Личный кабинет</span>
                                            <i className="icon__toggle">
                                                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.3887 1.5L6.38867 6.5L1.38867 1.5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                            </i>
                                        </Link>
                                        <div className="btn__lk-dropdown">
                                            <div onClick={()=>{
                                                show(<ChangePass/>, "modal--form modal--pass");
                                            }} className="btn__lk-dropdown-it">
                                                <i className="icon__logout"/>
                                                <span>
                                                   Сменить пароль
                                               </span>
                                            </div>
                                            <a onClick={()=>{
                                                if(window.ym){
                                                    window.ym(97392085,'reachGoal','кнопка Перехода в мессенджер');
                                                }
                                            }} href={'https://t.me/delicados_promo_bot?start=' + profile.data.promocode} target="_blank" className="btn__lk-dropdown-it d-flex align-items-center">
                                                <i className="icon__tg"/>
                                                <span>
                                                   Связать кабинет <br/>
                                                    с ассистентом акции
                                               </span>
                                            </a>

                                            <div onClick={()=>{
                                                localStorage.removeItem('auth_key');
                                                window.location.reload();
                                            }} className="btn__lk-dropdown-it">
                                                <i className="icon__logout"/>
                                                <span>
                                                   Выход
                                               </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                    <div onClick={()=>{
                        setNav(!nav);
                        if(document.body.classList.contains('overflow')) {
                            document.body.classList.remove('overflow');
                        }else {
                            document.body.classList.add('overflow');
                        }
                    }} className="nav__toggle mb-only">
                        {!nav && (
                            <i className="icon__bars fadeIn animated">
                                <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H28V2H0V0Z" fill="white"/>
                                    <path d="M0 8H28V10H0V8Z" fill="white"/>
                                    <path d="M0 16H28V18H0V16Z" fill="white"/>
                                </svg>
                            </i>
                        )}

                        {nav && (
                            <i className="icon__close fadeIn animated">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.393311 20.1919L20.1923 0.392905L21.6065 1.80712L1.80752 21.6061L0.393311 20.1919Z" fill="white"/>
                                    <path d="M21.6064 20.1909L1.80746 0.391928L0.393242 1.80614L20.1922 21.6051L21.6064 20.1909Z" fill="white"/>
                                </svg>
                            </i>
                        )}
                    </div>
                </div>
            </div>
            {!inner && (
                <div onClick={()=>{
                    goToAnchor('promo');
                    if(window.ym){
                        window.ym(97392085,'reachGoal','кнопка Деликадос');
                    }
                }} className={'logo' + (nav ? ' nav--open' : '') +  (fixed ? ' logo--fixed' : '')}>
                    <img src={require("../assets/img/logo.png")}/>
                </div>
            )}
            {inner && (
                <Link to="/" className={'logo' + (nav ? ' nav--open' : '') + (fixed ? ' logo--fixed' : '')}>
                    <img src={require("../assets/img/logo.png")}/>
                </Link>
            )}

            <a onClick={()=>{
                if(window.ym){
                    window.ym(97392085,'reachGoal','кнопка Где купить');
                }
            }} href="https://redirect.appmetrica.yandex.com/serve/893662341932054611" target="_blank" className={'logo__magnit' + (nav ? ' nav--open' : '')}>
                <img src={require("../assets/img/magnit.svg").default}/>
            </a>
        </>
    );
}

export default Header;
