import parse from 'html-react-parser';
import React from "react";

function Info({title = '', subtitle = '', text = '', icon = false, close}){
    return (
        <>
            <div className="modal__ct">
                {icon && (
                    <div className="modal__ct-box">
                        <i className="icon__success" aria-hidden={true}/>
                    </div>
                )}
                {title && (
                    <div className="modal__ct-box">
                        <div className="modal__title">
                            {parse(title)}
                        </div>
                    </div>
                )}
                {text && (
                    <div className="modal__ct-box">
                        <div className="modal__text">
                            {parse(text)}
                        </div>
                    </div>
                )}
                <div className="modal__ct-box modal__action-close">
                    <div onClick={()=>{
                        close()
                    }} className="btn">
                        <span>Хорошо</span>
                        <i className="btn__arrow">
                            <svg>
                                <use href="#btn-arrow"></use>
                            </svg>
                        </i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Info;
