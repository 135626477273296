import { createContext, ReactNode, useContext, useState, useEffect } from 'react'
import {Modal} from '../components/modal.js';

const  modalContextDefaultValues = {
    isShown: false,
    lock: false,
    modalContent:<></>,
    className:"",
    hide: () => {},
    show: ()=>{}
};

const ModalContext = createContext(modalContextDefaultValues);

export const useModal=() =>{
    return useContext(ModalContext);
}

export const ModalProvider  = ({ children }) =>{
    const [isShown, setIsShown] = useState(false);
    const [modalContent, setModalContent] = useState(<></>);
    const [className, setClassName] = useState("");
    const [lock, setLock] = useState(false);

    const hide = () => {
        if(!lock) {
            setIsShown(false);

            if(document.getElementById('enable-scroll')){
                document.getElementById('enable-scroll').click();
            }

            setTimeout(function (){
                setClassName("");
                document.body.classList.remove('modal--open');
                setModalContent(<></>);
            }, 200);
        }
    };

    const show = (elem, className, lock = false) => {
        if (elem){
            setModalContent(elem);
            setClassName(className);
            setLock(lock);
            setIsShown(true);
            if(document.getElementById('disable-scroll')){
               // document.getElementById('disable-scroll').click();
            }

            if(className == 'modal--form modal--login'){

            }

            document.body.classList.add('modal--open');

            return
        }
        setIsShown(false);
    };

    const value = {
        isShown,
        modalContent,
        className,
        hide,
        show
    };
  return (
      <ModalContext.Provider value={value}>
          {children}
          <Modal isShown={isShown} modalContent={modalContent} hide={hide} className={className}/>
      </ModalContext.Provider>
  )
}
