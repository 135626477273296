import {Link, useLocation} from 'react-router-dom'
import React, {useEffect} from "react";
import axiosInstance from "../../helpers/axios";
import {useModal} from "../../context";
import useEffectOnce from "../../hooks/useEffectOnce";

function Prize({prize, close}){
    const location = useLocation();
    const {hide} = useModal();

    useEffectOnce(()=>{
        pushPrize();
    });

    const pushPrize = async () => {
        try {
            const response = await axiosInstance.post('/prizes/api/push/' + prize.id);
        } catch (error) {

        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    {prize.type == 1 && (
                        <div className="modal__title">
                            Ваш гарантированный <br/>
                            приз – игры для вечеринки
                        </div>
                    )}

                    {prize.type == 2 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            электронный сертификат <br/>
                            «Магнит» номиналом 1000 ₽
                        </div>
                    )}

                    {prize.type == 3 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            электронный сертификат <br/>
                            «Магнит» номиналом 2000 ₽
                        </div>
                    )}

                    {prize.type == 4 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            сертификат М.Видео <br/>
                            на покупку портативной <br/>
                            колонки
                        </div>
                    )}
                    {prize.type == 5 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            сертификат М.Видео <br/>
                            на покупку Party-колонки
                        </div>
                    )}
                    {prize.type == 6 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            сертификат М.Видео <br/>
                            на покупку Домашнего <br/>
                            кинотеатра
                        </div>
                    )}

                    {prize.type == 7 && (
                        <>
                            <div className="modal__title">
                                Вы выиграли приз – <br/>
                                большой party-set
                            </div>
                            <div className="modal__subtitle">
                                Delicados: Nachos, соусы, тортильи <br/>
                                Яркий праздничный аксессуар <br/>
                                Подписка на Яндекс.Плюс на 1 год
                            </div>
                        </>
                    )}

                    {prize.type == 8 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            вечеринку на 500 000 ₽
                        </div>
                    )}

                    {prize.type == 9 && (
                        <div className="modal__title">
                            Вы выиграли приз – <br/>
                            Годовой запас Delicados
                        </div>
                    )}
                </div>
                <div className="modal__ct-box">
                    <div className="modal__prize">
                        <div className="modal__prize-img">
                            <img src={require('../../assets/img/modal-prizes/' + prize.type + '.png')}/>
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    {prize.type < 7 && (
                        <div className="modal__text">
                            Информация о&nbsp;призе находится в&nbsp;личном <br/>
                            кабинете в&nbsp;разделе
                            {location.pathname != '/cabinet' && (
                                <Link onClick={()=>{
                                    hide();
                                }} to="/cabinet#prizes">«Мои призы»</Link>
                            )}
                            {location.pathname === '/cabinet' && (
                                <a onClick={()=>{
                                    document.querySelector('#link-prizes').click();
                                    hide();
                                }}>«Мои призы»</a>
                            )}
                        </div>
                    )}

                    {prize.type >= 7 && (
                        <div className="modal__text">
                            Для подтверждения статуса победителя необходимо <b>в&nbsp;течение&nbsp;2 (двух) рабочих дней</b> с&nbsp;момента получения письма на&nbsp;электронную почту выслать на&nbsp;адрес электронной почты Организатора <a href="mailto:info@delicados-promo.ru">info@delicados-promo.ru</a> следующие достоверные документы/сведения о&nbsp;себе:
                        </div>
                    )}
                </div>
                {prize.type >= 7 && (
                    <div className="modal__ct-box">
                        <div className="doc__list">
                            <ol>
                                <li>
                                    ФИО;
                                </li>
                                <li>
                                    Контактный номер телефона;
                                </li>
                                <li>
                                    Точный адрес проживания для доставки Приза с&nbsp;обязательным указанием: индекс, регион, область, город/населенный пункт, улица, дом (корпус/строение), квартира;
                                </li>
                                <li>
                                    Дата рождения;
                                </li>
                                <li>
                                    Фото/скан страниц своего паспорта гражданина&nbsp;РФ (разворот с&nbsp;фотографией и&nbsp;страница с&nbsp;информацией о&nbsp;последнем месте регистрации);
                                </li>
                                <li>
                                    Фото/скан свидетельства ИНН;
                                </li>
                                <li>
                                    Скан подписанного согласия на&nbsp;обработку персональных данных в&nbsp;соответствии с&nbsp;Приложением 1&nbsp;к настоящим Правилам;
                                </li>
                                <li>
                                    Иная необходимая информация по&nbsp;запросу Организатора.
                                </li>
                            </ol>
                        </div>
                    </div>
                )}
                {prize.type == 1 && prize.prize_link && (
                    <div className="modal__ct-box d-flex">
                        <a href={prize.prize_link} target="_blank" download className="btn">
                            <span>Скачать</span>
                            <i className="btn__arrow">
                                <svg>
                                    <use href="#btn-download"></use>
                                </svg>
                            </i>
                        </a>
                    </div>
                )}

                {prize.type >= 7 && (
                    <div className="modal__ct-box d-flex">
                        <div onClick={()=>{
                            hide();
                        }} className="btn">
                            <span>Закрыть</span>
                            <i className="btn__arrow">
                                <svg>
                                    <use href="#btn-arrow"></use>
                                </svg>
                            </i>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Prize;
