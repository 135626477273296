import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ProfileProvider, ModalProvider, EndProvider} from "./context";
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Cabinet from "./pages/Cabinet";
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title></title>
              <meta property="og:title" content=""/>
          </Helmet>
          <EndProvider>
              <ProfileProvider>
                  <ModalProvider>
                      <div id="wrapper" className="wrapper">
                          <Header/>
                          <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/cabinet" element={<Cabinet />} />
                          </Routes>
                          <Footer/>
                      </div>
                  </ModalProvider>
              </ProfileProvider>
          </EndProvider>

      </>
  );
}

export default App;
